<template>
  <section class="profile">
    <div class="page-header">
      <h3 class="page-title">
        Transaction {{ transaction.id }}
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link :to="{name: 'transaction-list'}">Transactions</router-link></li>
          <li class="breadcrumb-item active" aria-current="page">{{ transaction.id }}</li>
        </ol>
      </nav>
    </div>
    <div class="row">
    <div class="col-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-body">
          <b-tabs class="tab-solid tab-solid-primary">
            <b-tab active>
              <template slot="title">
                <i class='mdi mdi-buffer'></i> Summary
              </template>
              <form class="forms-sample">
                <div class="row">
                  <div class="col-md-6 grid-margin stretch-card">
                    <div class="card">
                      <div class="card-body">
                        <b-form-group label="Plot ID"  label-for="plotId">
                          <b-form-input type="text" disabled id="plotId" v-model="transaction.plot_id" placeholder="Plot ID"></b-form-input>
                        </b-form-group>
                        <b-form-group label="Plot No"  label-for="plotNo">
                          <b-form-input type="text" disabled id="plotNo" v-model="transaction.plot_no" placeholder="Plot No"></b-form-input>
                        </b-form-group>
                        <b-form-group label-for="plan" horizontal label="Plan">
                          <b-form-input type="text" disabled id="plan" v-model="transaction.plan" placeholder="Plan"></b-form-input>
                        </b-form-group>
                        <b-form-group label-for="meterSq" horizontal label="Meter Sq">
                          <b-form-input type="text" disabled id="meterSq" v-model="transaction.meter_sq" placeholder="Meter Sq"></b-form-input>
                        </b-form-group>
                        <b-form-group label-for="pricePerSqMeter" horizontal label="Price per Square Meter">
                          <b-form-input type="text" disabled id="pricePerSqMeter" v-model="transaction.price_per_sq_meter" placeholder="Price Per Sq Meter"></b-form-input>
                        </b-form-group>
                        <b-form-group label-for="city" horizontal label="City">
                          <b-form-input type="text" disabled id="city" v-model="transaction.city" placeholder="City"></b-form-input>
                        </b-form-group>
                        <b-form-group label-for="district" horizontal label="District">
                          <b-form-input type="text" disabled id="district" v-model="transaction.district" placeholder="District"></b-form-input>
                        </b-form-group>
                        <b-form-group label-for="blocks" horizontal label="Blocks">
                          <b-form-input type="text" disabled id="blocks" v-model="blocks" placeholder="Blocks"></b-form-input>
                        </b-form-group>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 grid-margin stretch-card">
                <div class="card">
                  <div class="card-body">
                    <b-form-group label="Invoice No"  label-for="invoiceNo">
                      <b-form-input type="text" disabled id="invoiceNo" v-model="transaction.invoice_no" placeholder="Invoice No"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Amount"  label-for="amount">
                      <b-form-input type="text" disabled id="amount" v-model="transaction.amount" placeholder="Amount"></b-form-input>
                    </b-form-group>
                    <b-form-group label="En Date"  label-for="enDate">
                      <b-form-input type="text" disabled id="enDate" v-model="transaction.en_date" placeholder="En Date"></b-form-input>
                    </b-form-group>
                    <b-form-group label-for="hijDate" horizontal label="Hij Date">
                      <b-form-input type="text" disabled id="hijDate" v-model="transaction.hij_date" placeholder="Hij Date"></b-form-input>
                    </b-form-group>
                    <b-form-group label-for="classification" horizontal label="Classification">
                      <b-form-input type="text" disabled id="classification" v-model="transaction.classification" placeholder="Classification"></b-form-input>
                    </b-form-group>
                    <b-form-group label-for="subClassification" horizontal label="Sub Classification">
                      <b-form-input type="text" disabled id="sub_classification" v-model="transaction.sub_classification" placeholder="Sub Classification"></b-form-input>
                    </b-form-group>
                    <b-form-group label-for="created_at" horizontal label="Created At">
                      <b-form-input type="text" disabled id="created_at" v-model="created_at" placeholder="Created At"></b-form-input>
                    </b-form-group>
                    <b-form-group label-for="updated_at" horizontal label="Updated At">
                      <b-form-input type="text" disabled id="updated_at" v-model="updated_at" placeholder="Updated At"></b-form-input>
                    </b-form-group>
                </div>
              </div>
                </div>
                <div class="col-12 grid-margin stretch-card">
                  <div class="card">
                    <div class="card-body">
                      <b-form-group class="float-right">
                        <b-button variant="success" :to="{name: 'transaction-edit', params: {id: transaction.id}}" class="mr-2">Edit</b-button>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>
              </form>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
    </div>
  </section>
</template>

<script>

//import StarRating from 'vue-star-rating'
import helper from '../../util/helper.js'

export default {
  name: 'profile',
  data() {
    return {
      transaction: {}
    }
  },
  computed: {
    blocks: {
      get() {
        if(this.transaction.blocks)
          return this.transaction.blocks.join(",")
        return ""
      },
      set(val) {
        this.transaction.blocks = val.split(",")
      }
    },
    created_at: {
      get() {
        return this.formatTime(this.transaction.created_at, "d/M/Y")
      },
      set(val) {
        this.transaction.created_at = val
      }
    },
    updated_at: {
      get() {
        if(this.transaction.updated_at != null)
          return this.formatTime(this.transaction.updated_at, "d/M/Y")
        return ""
      },
      set(val) {
        this.transaction.updated_at = val
      }
    }
  },
  components: {
    //StarRating
  },
  methods: {
    formatTime(time, format) {
      return helper.formatTime(time, format)
    }
  },
  created() {
    this.$store.dispatch("transaction/fetchTransaction", this.$route.params.id)
    .then(res => {
       this.transaction = res.data.data
    })
  }
}
</script>
